
import {defineComponent, ref} from "vue";
import {Communication} from "@/models/communications/Communication";
import ModalBase from "@/components/ModalBase.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {Person} from "@/models/people/Person";
import {bus} from "@/modules/eventBus";
import Multiselect from "@vueform/multiselect";
import {useRoute} from "vue-router";
import {CommunicationService} from "@/services/CommunicationService";
import {PeopleService} from "@/services/PeopleService";
import Swal from "sweetalert2";
import Button from "@/components/UI/Button.vue";
import {CommunicationType} from "@/models/enums/CommunicationType";
import {GroupService} from "@/services/GroupService";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {QuillEditor} from '@vueup/vue-quill'
import {CommunicationAttachment} from "@/models/communications/CommunicationAttachment";
import {UploadInstance} from "element-plus";
import Utilities from "@/utilities/Utilities";
import {useToast} from "vue-toastification";
import {WebAnalytics} from "@/modules/webAnalytics";
import {auth0} from "@/main";

export default defineComponent({
  setup(_, {emit}) {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let communication = ref<Communication>({
      content: '',
      attachments: [] as Array<CommunicationAttachment>
    } as Communication);
    let tab = ref<string>('general');
    let mode = ref<string>('add');
    let step = ref<number>(1);
    let people = ref<Array<Person>>([]);
    let groups = ref<Array<GroupViewModel>>([]);
    let isLoadingCommunication = ref<boolean>(false);
    const filesToUpload = ref<number>(0);
    const uploadRef = ref<UploadInstance>()

    const route = useRoute()
    const isOrganization = () => {
      return typeof route.name === "string" ? route.name.indexOf('organization') > -1 : false
    }
    const organizationId = () => {
      return route.params['organizationId'] as string
    }
    const productionId = () => {
      return route.params['productionId'] as string
    }
    const invokeAdd = () => {
      communication.value = {communicationId: '', content: '', attachments: [] as Array<CommunicationAttachment>} as Communication
      tab.value = 'general'
      mode.value = 'add'
      step.value = 1
      filesToUpload.value = 0
      modal.value?.toggleOpen()
      fetch()
    }
    const invokeUpdate = (_communicationId: string) => {
      isLoadingCommunication.value = true
      tab.value = 'general'
      mode.value = 'update'
      step.value = 2
      filesToUpload.value = 0
      modal.value?.toggleOpen()
      CommunicationService.getCommunication(_communicationId).then(response => {
        communication.value = response.data
        fetch()
      }).finally(() => {
        isLoadingCommunication.value = false
      })
    }
    const emitAdded = () => {
      Swal.fire('Communication Added')
      emit('added')
      bus.emit('modal:communications:refresh')
      modal.value?.toggleClosed()
    }
    const fetch = () => {
      if (isOrganization()) {
        PeopleService.fetchOrganization(organizationId()).then(response => {
          people.value = response.data
        })
        GroupService.fetchOrganization(organizationId()).then(response => {
          groups.value = response.data
        })
      } else {
        PeopleService.fetchProduction(productionId()).then(response => {
          people.value = response.data
        })
        GroupService.fetchProduction(productionId()).then(response => {
          groups.value = response.data
        })
      }
    }
    return {
      modal,
      invokeAdd,
      invokeUpdate,
      communication,
      mode,
      step,
      people,
      organizationId,
      productionId,
      isOrganization,
      emitAdded,
      groups,
      tab,
      filesToUpload, uploadRef, isLoadingCommunication,
      v$: useVuelidate(), toast: useToast()
    }
  },
  components: {Button, InputGroup, ModalBase, Multiselect, QuillEditor},
  emits: ['added'],
  data() {
    return {
      isSaving: false,
      isUploading: false,
      isRetrievingLink: false,
      toolbar: [
        [{'header': []}],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'align': []}],
      ],
      uploadHeaders: {},
      uploadsInProgress: 0
    }
  },
  validations() {
    return {
      communication: {
        title: {required}
      }
    }
  },
  mounted() {
    bus.on('modal:communications:add', () => {
      this.invokeAdd()
    })
  },
  methods: {
    add(publish: boolean) {
      this.v$.communication.$touch()
      if (!this.v$.communication.$invalid) {
        this.isSaving = true
        CommunicationService.addCommunication({
          organizationId: this.organizationId(),
          productionId: this.isOrganization() ? '00000000-0000-0000-0000-000000000000' : this.productionId(),
          content: this.communication.type === CommunicationType.Text ? this.communication.title : this.communication.content,
          title: this.communication.title,
          type: this.communication.type,
          people: this.communication.people,
          groups: this.communication.groups
        }).then(response => {
          this.communication.communicationId = response.data.communicationId
          if (publish) {
            this.publish()
          } else {
            this.toast.success('Communication Saved')
            this.mode = 'update'
          }
        }).finally(() => {
          this.isSaving = false
          WebAnalytics.trackFlexible('Added Communication', {
            productionId: this.productionId(),
            type: this.communication.type === CommunicationType.Announcement ? 'Announcement' : 'Text',
            peopleCount: this.communication.people.length,
            groupCount: this.communication.groups.length
          })
        })
      }
    },
    update(publish: boolean) {
      this.v$.communication.$touch()
      if (!this.v$.communication.$invalid) {
        this.isSaving = true
        CommunicationService.updateCommunication(this.communication.communicationId, {
          communicationId: this.communication.communicationId,
          content: this.communication.type === CommunicationType.Text ? this.communication.title : this.communication.content,
          title: this.communication.title,
          type: this.communication.type,
          people: this.communication.people,
          groups: this.communication.groups
        }).then(() => {
          if (publish) {
            this.publish()
          } else {
            Swal.fire('Communication Updated')
            bus.emit('modal:communications:refresh')
            this.modal?.toggleClosed()
          }
        }).finally(() => {
          this.isSaving = false
          WebAnalytics.trackFlexible('Updated Communication', {
            productionId: this.productionId(),
            type: this.communication.type === CommunicationType.Announcement ? 'Announcement' : 'Text',
            peopleCount: this.communication.people.length,
            groupCount: this.communication.groups.length
          })
        })
      }
    },
    publish() {
      CommunicationService.publishCommunication(this.communication.communicationId).then(() => {
        this.toast.success('All set! Your communication has been sent out.')
        bus.emit('modal:communications:refresh')
        this.emitAdded()
      }).finally(() => {
        this.isSaving = false
        WebAnalytics.trackFlexible('Published Communication', {
          productionId: this.productionId(),
          type: this.communication.type === CommunicationType.Announcement ? 'Announcement' : 'Text',
          peopleCount: this.communication.people.length,
          groupCount: this.communication.groups.length
        })
      })
    },
    setType(type: number) {
      if (type === 1) {
        this.communication.type = CommunicationType.Announcement
      } else {
        this.communication.type = CommunicationType.Text
      }
      this.step = 2
    },
    getAttachmentLink(communicationAttachmentId: string) {
      this.isRetrievingLink = true
      CommunicationService.getAttachment(communicationAttachmentId).then(response => {
        setTimeout(() => {
          window.open(response.data.url, '_blank');
        })
      }).finally(() => {
        this.isRetrievingLink = false
        WebAnalytics.trackFlexible('Opened Communication Attachment', {
          productionId: this.communication.productionId
        })
      })
    },
    invokeUpload() {
      this.isUploading = true
      this.uploadRef?.submit()
    },
    uploadProgress() {
      this.uploadsInProgress++
    },
    uploadSuccess(response: CommunicationAttachment) {
      this.communication.attachments.push(response)
      WebAnalytics.trackFlexible('Uploaded Communication Attachment', {
        productionId: this.communication.productionId
      })
    },
    onChange(uploadFile: any, uploadFiles: any) {
      this.filesToUpload = uploadFiles.length
    },
    beforeUpload() {
      return auth0.getAccessTokenSilently().then(tokenResponse => {
        this.uploadHeaders = {'Authorization': `Bearer ${tokenResponse}`};
        return true;
      })
    },
    removeAttachment(communicationAttachmentId: string) {
      this.isSaving = true
      CommunicationService.removeAttachment(communicationAttachmentId).then(() => {
        Utilities.removeObject(this.communication.attachments, 'communicationAttachmentId', communicationAttachmentId)
      }).finally(() => {
        this.isSaving = false
        WebAnalytics.trackFlexible('Removed Communication Attachment', {
          productionId: this.communication.productionId
        })
      })
    },
    remove() {
      Swal.fire({
        title: "Are you sure?",
        confirmButtonText: 'Remove Communication',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Keep Communication'
      }).then(response => {
        if (response.isConfirmed) {
          this.isSaving = true
          CommunicationService.removeCommunication(this.communication.communicationId).then(() => {
            this.toast.success('Communication Removed')
            this.modal?.toggleClosed()
            bus.emit('modal:communications:refresh')
          }).finally(() => {
            this.isSaving = false
            WebAnalytics.trackFlexible('Removed Communication', {
              productionId: this.communication.productionId
            })
          })
        }
      })

    },
    convertToMB(bytes: number) {
      const decimals = 2
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  },
  computed: {
    peopleOptions(): Array<Object> {
      return this.people.filter(person => person.status === MembershipStatus.Active).map(person => {
        return {label: person.fullName, value: person.accountId}
      })
    },
    groupsOptions(): Array<Object> {
      return this.groups.map(group => {
        return {label: group.name, value: group.groupId}
      })
    },
    title(): string {
      return this.mode === 'add' ? 'Send New Communication' : 'Manage Communication'
    },
    uploadUrl(): string {
      return `${process.env.VUE_APP_API_BASE}/communications/${this.communication.communicationId}/attachments`
    },
    loadingText(): string {
      if (this.isLoadingCommunication) return 'Loading Communication';
      if (this.isUploading) return 'Uploading Attachments';
      if (this.isSaving) return 'Saving';
      if (this.isRetrievingLink) return 'Downloading Attachment';
      return 'Please Wait';
    },
    isLoading(): boolean {
      return this.isLoadingCommunication || this.isUploading || this.isSaving || this.isRetrievingLink
    }
  }
})
