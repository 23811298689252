export enum CommunicationStatus {
    Draft = 2,
    Published = 1
}

export const getCommunicationStatus = (status: CommunicationStatus) => {
    switch (status) {
        case CommunicationStatus.Draft:
            return { name: 'Draft', class: 'info' }
        case CommunicationStatus.Published:
            return { name: 'Published', class: 'success' }
        default:
            return { name: 'Unknown', class: 'warning' }
    }
}
